import React, { useState, useEffect } from 'react';
import Spinner from '../Spinner/Spinner';

interface EcosystemVitalsProps {
  artistName: string;
}

const EcosystemVitals: React.FC<EcosystemVitalsProps> = ({ artistName }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // We'll store raw data plus derived stage & health
  const [stats, setStats] = useState({
    totalPulses: 0,
    monthlySupport: 0,
    totalSupport: 0,
    ecosystemStage: '',
    ecosystemHealth: 0, // We'll treat this as a 0–100 numeric gauge
    ecosystemHealthLabel: '', // e.g., "Healthy" / "Unhealthy"
    ecosystemAdvice: '',
  });

  // --------------------------------------
  // Utility functions for Stage & Health
  // --------------------------------------

  // 1) Determine the ecosystem stage
  const getEcosystemStage = (
    pulsesInLast30Days: number,
    monthlySupport: number,
    totalPulses: number
  ): string => {
    if (totalPulses === 0) {
      return 'Vision';
    }
    // Follow your rules in order:
    //  - Resilient: monthlySupport > 150 && totalPulses > 10
    //  - Thriving: monthlySupport > 50
    //  - Coordinating: pulsesInLast30Days > 1
    //  - Emerging: pulsesInLast30Days === 1
    //  - Otherwise: "Dormant" (no pulses in the last 30 days)
    if (monthlySupport > 150 && totalPulses > 10) {
      return 'Resilient';
    } else if (monthlySupport > 50) {
      return 'Thriving';
    } else if (pulsesInLast30Days > 1) {
      return 'Coordinating';
    } else if (pulsesInLast30Days === 1) {
      return 'Emerging';
    } else {
      return 'Dormant'; // or any other label you'd like for "no recent pulses"
    }
  };

  // 2) Determine if earliest pulse is less than 6 months old
  const isEarliestPulseLessThan6Months = (earliestDateStr: string): boolean => {
    if (!earliestDateStr) return false; // fallback if no date found

    // Format: YYYYMMDD
    const year = parseInt(earliestDateStr.substring(0, 4));
    const month = parseInt(earliestDateStr.substring(4, 6)) - 1; // zero-based
    const day = parseInt(earliestDateStr.substring(6, 8));

    const earliestDate = new Date(year, month, day);
    const sixMonthsAgo = new Date();
    sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

    return earliestDate >= sixMonthsAgo;
  };

  // 3) Determine textual "health" from stage & earliest date
  const getEcosystemHealthLabel = (
    stage: string,
    earliestDateStr: string,
    totalPulses: number
  ): 'Healthy' | 'Unhealthy' | 'Neutral' => {
    if (stage === 'Emerging') {
      // If earliest pulse < 6 months ago => Healthy
      if (isEarliestPulseLessThan6Months(earliestDateStr)) {
        return 'Healthy';
      } else {
        // If earliest pulse is older than 6 months & there's only 1 pulse => Unhealthy
        if (totalPulses === 1) {
          return 'Unhealthy';
        }
      }
    }
    // You can define additional logic for other stages, or default to 'Neutral'
    return 'Neutral';
  };

  // 4) Map stage & health label to a 0–100 gauge
  const getEcosystemHealthGauge = (
    stage: string,
    healthLabel: string
  ): number => {
    // This is entirely up to you. 
    // Below is a simple example that can be tweaked.
    switch (stage) {
      case 'Vision':
        return 5;
      case 'Resilient':
        return 95;
      case 'Thriving':
        return 80;
      case 'Coordinating':
        return 65;
      case 'Emerging':
        if (healthLabel === 'Healthy') return 50;
        if (healthLabel === 'Unhealthy') return 25;
        return 35; // Some mid-point for "Neutral Emerging"
      case 'Dormant':
        return 15;
      default:
        return 10;
    }
  };

    const getEcosystemAdvice = (stage: string, healthLabel: string): string => {
    if (stage === 'Vision') {
      return (
        "This ecosystem is waiting to be brought to life. " +
        "By adding the first coral, you'll be pioneering a new " +
        "web of support and inspiring others to join."
      );
    }
    if (stage === 'Emerging') {
        if (healthLabel === 'Healthy') {
        return (
            "Initial coral formations are establishing support patterns. " +
            "Each pulse contributes to growing collective trust in " +
            "this emerging ecosystem."
        );
        } else if (healthLabel === 'Unhealthy') {
        return (
            "Early coral formations show potential. " +
            "Additional pulses will strengthen the foundation " +
            "of this developing ecosystem."
        );
        } else {
        return (
            "Recent pulse activity detected in this ecosystem. " +
            "Regular pulses from corals help establish " +
            "sustainable support patterns."
        );
        }
    } else if (stage === 'Coordinating') {
        return (
        "Multiple corals are pulsing in coordination. " +
        "This rhythm of support demonstrates growing " +
        "collective trust in the ecosystem."
        );
    } else if (stage === 'Thriving') {
        return (
        "Regular pulses from multiple corals have created " +
        "a diverse and strong foundation. This ecosystem shows " +
        "clear signs of established collective trust."
        );
    } else if (stage === 'Resilient') {
        return (
        "Steady pulses from numerous corals maintain " +
        "this ecosystem's vitality. A strong network of " +
        "support flows through established trust paths."
        );
    } else if (stage === 'Dormant') {
        return (
        "This ecosystem's corals are currently inactive. " +
        "New pulses will reactivate support flows and " +
        "ecosystem vitality."
        );
    }
    return '';
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stage = process.env.REACT_APP_ENVIRONMENT || 'dev';
        const url = `https://wearecoral-public-data-${stage}.s3.ap-southeast-2.amazonaws.com/wearecoral-contribution-report.csv`;
        const response = await fetch(url);
        const text = await response.text();
        const rows = text.split('\n').slice(1); // Skip header

        // We'll count every row for the correct artist as a pulse
        let allPulsesCount = 0;
        let earliest = '99999999';
        let monthlyTotal = 0;
        let allTimeTotal = 0;

      // For "pulses in last 30 days"
      let pulsesInPeriod = 0;
      // In the useEffect:
      let mostRecentDate = '00000000';
      rows.forEach((row) => {
        if (!row.trim()) return;
        const [dateStr, , , name] = row.split(',');
        if (name && name.toLowerCase() === artistName.toLowerCase()) {
          if (dateStr > mostRecentDate) {
            mostRecentDate = dateStr;
          }
        }
      });

      // Calculate the date range
      const recentYear = parseInt(mostRecentDate.substring(0, 4));
      const recentMonth = parseInt(mostRecentDate.substring(4, 6)) - 1;
      const recentDay = parseInt(mostRecentDate.substring(6, 8));
      const mostRecentTransactionDate = new Date(recentYear, recentMonth, recentDay);
      const oneMonthBefore = new Date(mostRecentTransactionDate);
      oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);

      // Process transactions
      rows.forEach((row) => {
        if (!row.trim()) return;
        const [dateStr, , amount, name] = row.split(',');

        if (name && name.toLowerCase() === artistName.toLowerCase()) {
          allPulsesCount += 1;

          if (dateStr < earliest) {
            earliest = dateStr;
          }

          const floatAmount = parseFloat(amount || '0');
          allTimeTotal += floatAmount;

          // Check if within one month period from most recent
          const year = parseInt(dateStr.substring(0, 4));
          const month = parseInt(dateStr.substring(4, 6)) - 1;
          const day = parseInt(dateStr.substring(6, 8));
          const transactionDate = new Date(year, month, day);

          if (transactionDate >= oneMonthBefore && 
              transactionDate <= mostRecentTransactionDate) {
            monthlyTotal += floatAmount;
            pulsesInPeriod += 1;
          }
        }
      });

        // Derive Stage
        const stageLabel = getEcosystemStage(
          pulsesInPeriod,
          monthlyTotal,
          allPulsesCount
        );

        // Derive Health Label
        const healthLabel = getEcosystemHealthLabel(
          stageLabel,
          earliest,
          allPulsesCount
        );

        // Derive a numeric gauge 0–100
        const healthGauge = getEcosystemHealthGauge(stageLabel, healthLabel);

        // Advice text
        const adviceText = getEcosystemAdvice(stageLabel, healthLabel);

        setStats({
          totalPulses: allPulsesCount,
          monthlySupport: Math.floor(monthlyTotal),
          totalSupport: Math.floor(allTimeTotal),
          ecosystemStage: stageLabel,
          ecosystemHealth: healthGauge,
          ecosystemHealthLabel: healthLabel,
          ecosystemAdvice: adviceText,
        });

        setLoading(false);
      } catch (err) {
        setError('Failed to load ecosystem data');
        setLoading(false);
      }
    };

    if (artistName) {
      fetchData();
    }
  }, [artistName]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-32">
        <Spinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 text-sm">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-coral-blue/90 rounded-lg p-6">
      <h4 className="text-sm font-bold text-coral-white mb-4 uppercase tracking-wider">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
          Ecosystem Vitals
        </span>
      </h4>
      <div className="flex gap-8 items-center">
        {/* Total Pulses */}
        <div>
          <p className="text-2xl font-bold text-white">{stats.totalPulses}</p>
          <p className="text-sm text-white/80">Pulses</p>
        </div>

        {/* Monthly Support */}
        <div>
          <p className="text-2xl font-bold text-white">${stats.monthlySupport}</p>
          <p className="text-sm text-white/80">Monthly</p>
        </div>

        {/* Total Support */}
        <div>
          <p className="text-2xl font-bold text-white">${stats.totalSupport}</p>
          <p className="text-sm text-white/80">Total</p>
        </div>

        {/* Stage & Health Gauge */}
        <div className="border-l border-coral-black/20 pl-8">
          <p className="text-sm font-medium text-white mb-1">
            Stage: {stats.ecosystemStage}
          </p>
          <div className="w-24 bg-gray-200 rounded-full h-1.5">
            <div
              className="bg-gradient-to-r from-coral-pink to-coral-orange h-1.5 rounded-full"
              style={{ width: `${stats.ecosystemHealth}%` }}
            ></div>
          </div>
          <p className="text-xs text-white/70 mt-1">
            Health: {stats.ecosystemHealthLabel}
          </p>
        </div>
      </div>

      {/* Advice / Next Steps */}
      {stats.ecosystemAdvice && (
        <div className="mt-4 p-3 rounded-md bg-coral-black/20 text-white text-sm">
          <p>{stats.ecosystemAdvice}</p>
        </div>
      )}
    </div>
  );
};

export default EcosystemVitals;