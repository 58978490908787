import React, { useState, useEffect, useRef } from 'react';
import mainBackgroundImage from '../../../assets/images/coral-reef-black.svg';
import blueWaveImage from '../../../assets/images/blue-wave.png';
import darkBlueWaveImage from '../../../assets/images/dark-blue-wave.png';
import FAQItem from '../../../components/FAQItem/faqItem';
import { faqData } from './faqData';
import CollectiveImpactCalculator from '../../../components/CollectiveImpactTable/CollectiveImpactCalculator';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import RecentCoralVisualisation from '../../../components/RecentCoralVisualisation/RecentCoralVisualisation';
import CoralEcosystem from '../../../components/CoralEcosystem/CoralEcosystem';
import { Tooltip } from 'react-tooltip';
import AllInOneCoralCreator from '../../../components/AllInOneCoralCreator/AllInOneCoralCreator';
import CoralButton from '../../../components/CoralButtons/CoralButton';

import heroGuitarSmall from '../../../assets/images/responsive/hero-image-man-guitar-small.jpg';
import heroGuitarMedium from '../../../assets/images/responsive/hero-image-man-guitar-medium.jpg';
import heroGuitarLarge from '../../../assets/images/responsive/hero-image-man-guitar-large.jpg';
import heroProducerSmall from '../../../assets/images/responsive/hero-image-producer-small.jpg';
import heroProducerMedium from '../../../assets/images/responsive/hero-image-producer-medium.jpg';
import heroProducerLarge from '../../../assets/images/responsive/hero-image-producer-large.jpg';
import heroMcSmall from '../../../assets/images/responsive/hero-image-mc-small.jpg';
import heroMcMedium from '../../../assets/images/responsive/hero-image-mc-medium.jpg';
import heroMcLarge from '../../../assets/images/responsive/hero-image-mc-large.jpg';

const Home: React.FC = () => {
  // Track page view
  useEffect(() => {
    setTimeout(() => {
      trackEvent(EventName.HOME_PAGE_VIEW, {});
    }, 0);
  }, []);

  // State for the active image index and to know when the first image is ready.
  const [activeImage, setActiveImage] = useState(0);
  const [isFirstImageLoaded, setIsFirstImageLoaded] = useState(false);
  const coralCreatorRef = useRef<HTMLDivElement>(null);

  const heroImageSets = [
    {
      small: heroGuitarSmall,
      medium: heroGuitarMedium,
      large: heroGuitarLarge,
    },
    {
      small: heroProducerSmall,
      medium: heroProducerMedium,
      large: heroProducerLarge,
    },
    {
      small: heroMcSmall,
      medium: heroMcMedium,
      large: heroMcLarge,
    },
  ];

  // Return the appropriate URL based on the viewport width.
  const getResponsiveImage = (imageSet: typeof heroImageSets[0]) => {
    if (typeof window === 'undefined') return imageSet.large;
    if (window.innerWidth < 640) return imageSet.small;
    if (window.innerWidth < 1024) return imageSet.medium;
    return imageSet.large;
  };

  // Build an array of hero image URLs.
  const heroImages = heroImageSets.map(imageSet => getResponsiveImage(imageSet));

  // Utility to preload an image. If the image is already cached, it resolves immediately.
  const preloadImage = (src: string): Promise<void> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = src;
      if (img.complete) {
        resolve();
      } else {
        img.onload = () => resolve();
      }
    });
  };

  // Preload the first image, then allow rendering.
  useEffect(() => {
    const firstSrc = heroImages[0];
    const img = new Image();
    img.src = firstSrc;
    img.onload = () => setIsFirstImageLoaded(true);
  }, [heroImages]);

  // Rotate the active image every 10 seconds, but only update when the next image is loaded.
  useEffect(() => {
    const timer = setTimeout(() => {
      const nextIndex = activeImage === heroImages.length - 1 ? 0 : activeImage + 1;
      preloadImage(heroImages[nextIndex]).then(() => {
        setActiveImage(nextIndex);
      });
    }, 10000);
    return () => clearTimeout(timer);
  }, [activeImage, heroImages]);

  const scrollToCoralCreator = () => {
    if (coralCreatorRef.current) {
      setTimeout(() => {
        coralCreatorRef.current!.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 100);
    }
  };

  return (
    <div className="flex flex-col min-h-screen mx-auto overflow-hidden">
      {/* Hero Section with Background Image */}
      <section className="relative w-full flex items-center justify-center min-h-[70vh] sm:min-h-[75vh] overflow-hidden">
        {isFirstImageLoaded ? (
          <>
            {heroImageSets.map((imageSet, index) => (
              <div
                key={index}
                className={`absolute inset-0 bg-cover bg-top transition-opacity duration-500 ease-in-out ${activeImage === index ? 'opacity-100' : 'opacity-0'}`}
                style={{
                  backgroundImage: `url(${getResponsiveImage(imageSet)})`,
                  backgroundColor: '#0B0D0D',
                }}
              />
            ))}
            <div className="absolute inset-0 bg-gradient-to-b from-transparent via-transparent to-[#0B0D0D] opacity-90" />
            <div className="absolute inset-0 bg-black bg-opacity-30" />
            <div
              ref={coralCreatorRef}
              className="relative w-full max-w-screen-2xl mx-auto px-3 pt-24 md:pt-60 pb-0 md:pb-24 flex flex-col md:flex-row items-center justify-center"
            >
              <div className="md:w-5/12 px-5 text-center md:text-left">
                <h1 className="font-syne text-5xl sm:text-6xl md:text-7xl text-white font-medium mb-6 leading-tight">
                  Back the musicians who move you.
                </h1>
                <p className="font-syne font-semibold text-xl sm:text-2xl md:text-3xl text-gray-200">
                  Express what their music means to you, simply and directly.
                </p>
              </div>
              <div className="backdrop-blur-md rounded-lg shadow-xl p-4 hidden md:block">
                <AllInOneCoralCreator transparent={true} />
              </div>
            </div>
          </>
        ) : (
          <div className="absolute inset-0 bg-[#0B0D0D] flex items-center justify-center">
            <div className="w-full max-w-screen-2xl mx-auto px-3">
              <div className="md:w-5/12 px-5 text-center md:text-left">
                <h1 className="font-syne text-5xl sm:text-6xl md:text-7xl text-white font-medium mb-6 leading-tight">
                  Back the musicians who move you.
                </h1>
              </div>
            </div>
          </div>
        )}
      </section>

      {/* Coral Details and Visualization Section */}
      <section className="bg-gradient-to-b from-[#080909] to-coral-deep-blue py-8 sm:py-16 px-4 lg:px-8 text-white">
        <div className="md:w-6/12 backdrop-blur-md rounded-lg shadow-xl p-4 block md:hidden">
          <AllInOneCoralCreator transparent={true} />
        </div>
        <div className="max-w-screen-lg mx-auto text-center pt-16 sm:pt-0">
          <h2 className="font-syne text-3xl sm:text-4xl pl-4 pr-4 mb-12">
            The music you love struggles in a system that transacts by the stream.
          </h2>
          <h2 className="font-syne text-3xl sm:text-4xl pl-4 pr-4 mb-8">
            We prefer a kinder, more sustainable way of backing artists. You contribute any amount monthly to the artists who move you, giving them more space to create.
          </h2>
        </div>
      </section>
      <section
        className="relative bg-coral-deep-blue bg-no-repeat bg-contain bg-bottom 3xl:bg-cover text-white"
        style={{
          backgroundImage: `url(${mainBackgroundImage})`,
        }}
      >
        <div className="max-w-screen-lg mx-auto text-center px-3 lg:px-8">
          <h3 className="text-xl md:text-2xl mb-4">
            <span className="bg-clip-text font-extrabold text-transparent bg-gradient-to-r from-coral-pink to-coral-grad-blue-light">
              HERE&apos;S HOW
            </span>
          </h3>

          <h2 className="font-syne text-xl sm:text-2xl md:text-3xl max-w-3xl mx-auto mb-6">
            Personalise your Coral: add{' '}
            <span
              className="text-coral-pink"
              data-tooltip-id="any-artists"
              data-tooltip-html="Nominate artists, even if they're not on our platform.<br />We'll notify them of waiting funds.<br /><br />This community-driven approach reflects recognition of <br />the value we've already received from their music, and<br />is how we can step forward to value them.<br /><br />Artists have 9 months from the first notification to<br />sign up before funds are redistributed to other<br />artists you nominate, or refunded."
            >
              any artists
            </span>{' '}
            you want and set any monthly amount.
          </h2>

          <p className="font-syne text-xl sm:text-2xl md:text-3xl max-w-3xl mx-auto mb-6">
            Your monthly contribution goes directly to your chosen artists, so they can make more of the music you love.
          </p>

          <p className="font-syne text-transparent bg-clip-text bg-gradient-to-r from-coral-pink to-coral-orange text-xl sm:text-2xl md:text-3xl max-w-3xl mx-auto mb-12">
            Enjoy vibrant, diverse music scenes you help sustain.
          </p>

          <Tooltip id="any-artists" className="z-50" />

          <div className="mb-12">
            <RecentCoralVisualisation />
          </div>

          <div className="flex justify-center mb-16">
            <CoralButton onClick={scrollToCoralCreator}>PERSONALISE YOUR CORAL</CoralButton>
          </div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="bg-[#0C142D] text-white">
        <div className="p-8 md:p-16">
          <div className="max-w-screen-xl mx-auto">
            <div className="flex flex-col lg:flex-row lg:items-start lg:justify-between">
              <div className="lg:w-1/2 mb-8 lg:mb-0">
                <h2 className="text-xl md:text-2xl font-extrabold mb-2 sm:mb-4">
                  <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                    OUR VISION
                  </span>
                </h2>
                <h3 className="font-syne text-2xl md:text-4xl mb-0 sm:mb-8 leading-tight mr-0 sm:mr-12">
                  A thriving ecosystem where music is valued and diverse styles and scenes can prosper.
                </h3>
              </div>
              <div className="lg:w-1/2 mb-6 sm:mb-14">
                <CoralEcosystem />
              </div>
            </div>
            <div className="flex justify-center">
              <CoralButton onClick={scrollToCoralCreator}>Add Your Coral to the Ecosystem</CoralButton>
            </div>
          </div>
        </div>
        <img src={blueWaveImage} alt="Blue Wave" className="w-full" />
      </section>

      {/* How It Works Section */}
      <section className="bg-coral-deep-blue text-white">
        <div className="max-w-screen-xl mx-auto px-8 sm:px-6 lg:px-8">
          <h2 className="text-xl md:text-2xl font-extrabold mb-4">
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
              CORAL DYNAMICS
            </span>
          </h2>
          <CollectiveImpactCalculator />
          <div className="flex justify-center mt-16">
            <CoralButton onClick={scrollToCoralCreator}>PERSONALISE YOUR CORAL</CoralButton>
          </div>
        </div>
        <div className="relative mt-16">
          <img src={darkBlueWaveImage} alt="Dark Blue Wave" className="w-full" />
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-coral-dark-blue to-transparent"></div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="bg-coral-dark-blue text-white p-8 md:p-16">
        <div className="max-w-screen-xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16">
            <div>
              <h2 className="text-xl md:text-2xl font-extrabold mb-4">
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-coral-pink to-coral-orange">
                  SUPPORT
                </span>
              </h2>
              <h3 className="font-syne text-3xl md:text-4xl mb-6 leading-tight">FAQs</h3>
              <p className="text-xl mb-8">
                Everything you need to know about wearecoral and how it operates.
              </p>
            </div>
            <div className="space-y-4">
              {faqData.map((faq, index) => (
                <FAQItem key={index} question={faq.question} answer={faq.answer} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;