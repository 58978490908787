// api.ts

// ------------------------------------
// Imports
// ------------------------------------
import { 
    BaseCoral, 
    ClientCoral, 
    CoralType, 
    Tag, 
    UserDashboard, 
    CoralSimulationResult, 
    User, 
    ArtistApplication, 
    BlendedArtistListItem, 
    UserIdentityVerification, 
    UserCoralConnection, 
    ProcessedCoralPayments, 
    ArtistSearchResult, 
    ArtistAnalytics, 
    CoralContentSummary,
    SignalRecord
  } from 'shared/types/platformTypes';
  import { getCognitoTokens } from '../utils/authUtils';
  
  // ------------------------------------
  // Constants
  // ------------------------------------
  const apiEndpoint = process.env.REACT_APP_API_GATEWAY_ENDPOINT;
  const S3_BUCKET_URL = `https://wearecoral-public-data-${
    process.env.REACT_APP_ENVIRONMENT || 'dev'
  }.s3.ap-southeast-2.amazonaws.com`;
  const localApiEndpoint = 'http://localhost:3001';
  const localApiEnabled = false;
  
  // ------------------------------------
  // Interfaces
  // ------------------------------------
  export interface ArtistSearchResponse {
    artists: ArtistSearchResult[];
    lastEvaluatedKey?: string;
  }
  
  export interface UpdateCoralVisibilityResponse {
    message: string;
    sharedCoralGuid: string;
    isShared: boolean;
    url: string;
  }
  
  export interface UpdateCoralCuratedResponse {
    message: string;
    isCurated: boolean;
  }
  
  export interface SimulateCoralResponse {
    transactionFee: number;
    platformFee: number;
    artistAllocations: CoralSimulationResult[];
  }
  
  export interface VerifyArtistRequest {
    verificationUrl: string;
    verificationCode: string;
    email: string;
    paypalEmail: string;
  }
  
  // ------------------------------------
  // Utility: Fetch from S3
  // ------------------------------------
  async function fetchFromS3<T>(key: string): Promise<T | null> {
    try {
      const response = await fetch(`${S3_BUCKET_URL}/${key}`);
      if (!response.ok) {
        return null;
      }
      return await response.json();
    } catch (error) {
      console.error('Error fetching from S3:', error);
      return null;
    }
  }
  
  // ------------------------------------
  // Generic API Calls
  // ------------------------------------
  async function fetchFromAPI<T>(
    endpoint: string,
    processor: (data: T) => T,
    method: 'GET' | 'POST' | 'DELETE' = 'GET',
    body?: string,
    useLocal = true
  ): Promise<T> {
    const tokens = await getCognitoTokens();
    if (!tokens) {
      throw new Error('Could not retrieve Cognito idToken');
    }
    const idTokenString = tokens.idToken;
  
    const response = await fetch(
      `${useLocal && localApiEnabled ? localApiEndpoint : apiEndpoint}${endpoint}`,
      {
        method,
        headers: {
          Authorization: `Bearer ${idTokenString}`,
          'Content-Type': 'application/json',
        },
        body,
      }
    );
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  
    const data = await response.json();
    if (isType<T>(data)) {
      return processor(data);
    } else {
      throw new Error('Invalid data type');
    }
  }
  
  export async function fetchFromAPIWithoutAuth<T>(
    endpoint: string,
    processor: (data: T) => T,
    method: 'GET' | 'POST' | 'DELETE' = 'GET',
    body?: string,
    useLocal = true
  ): Promise<T> {
    const apiEndpoint = process.env.REACT_APP_API_GATEWAY_ENDPOINT;
    const response = await fetch(
      `${useLocal && localApiEnabled ? localApiEndpoint : apiEndpoint}${endpoint}`,
      {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }
    );
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
  
    const data = await response.json();
    if (isType<T>(data)) {
      return processor(data);
    } else {
      throw new Error('Invalid data type');
    }
  }
  
  function isType<T>(data: unknown): data is T {
    // Basic type check; can be expanded if needed
    return typeof data === 'object' && data !== null;
  }
  
  // ------------------------------------
  // Artist-Related Functions
  // ------------------------------------
  export async function getArtistDetails(artistName: string): Promise<BlendedArtistListItem[]> {
    const endpoint = `/artist/get?artistName=${encodeURIComponent(artistName)}`;
    return fetchFromAPI<BlendedArtistListItem[]>(
      endpoint, 
      (data) => data as BlendedArtistListItem[], 
      'GET', 
      undefined, 
      false
    );
  }
  
  export async function getArtistDetailsByGuid(artistGuid: string): Promise<BlendedArtistListItem[]> {
    const endpoint = `/artist/get?artistId=${encodeURIComponent(artistGuid)}`;
    return fetchFromAPI<BlendedArtistListItem[]>(
      endpoint, 
      (data) => data as BlendedArtistListItem[], 
      'GET', 
      undefined, 
      false
    );
  }
  
  export async function getArtistDetailsPublic(
    artistUrl?: string, 
    artistName?: string, 
    artistId?: string, 
    cache?: boolean
  ): Promise<BlendedArtistListItem[]> {
    let s3Key: string | null = null;
  
    if (artistUrl) {
      s3Key = `artist-cache/${encodeURIComponent(artistUrl)}.json`;
    }
  
    if (s3Key) {
      const s3Data = await fetchFromS3<BlendedArtistListItem>(s3Key);
      if (s3Data) {
        return [s3Data];
      }
    }
  
    // If not in S3 or no valid key, fall back to API call
    let endpoint: string;
    if (artistUrl) {
      endpoint = `/artist/get/public?artistUrl=${encodeURIComponent(artistUrl)}`;
    } else if (artistName) {
      endpoint = `/artist/get/public?artistName=${encodeURIComponent(artistName)}`;
    } else if (artistId) {
      endpoint = `/artist/get/public?artistId=${encodeURIComponent(artistId)}`;
    } else {
      throw new Error('Either artistUrl, artistName, or artistId must be provided');
    }
  
    if (cache) {
      endpoint += `&cache=true`;
    }
  
    return fetchFromAPIWithoutAuth<BlendedArtistListItem[]>(
      endpoint, 
      (data) => data as BlendedArtistListItem[], 
      'GET', 
      undefined, 
      false
    );
  }
  
  export async function addArtistToMailingList(
    artistName: string, 
    email: string
  ): Promise<{ message: string }> {
    const endpoint = `/artist/mailinglist`;
    const body = JSON.stringify({ artistName, email });
    return fetchFromAPIWithoutAuth<{ message: string }>(
      endpoint, 
      (data) => data as { message: string }, 
      'POST', 
      body, 
      false
    );
  }
  
  export async function verifyArtist(
    request: VerifyArtistRequest
  ): Promise<{ success: boolean; message: string }> {
    const endpoint = `/artist/verify`;
    const body = JSON.stringify(request);
    return fetchFromAPIWithoutAuth<{ success: boolean; message: string }>(
      endpoint,
      (data) => data as { success: boolean; message: string },
      'POST',
      body
    );
  }
  
  // ------------------------------------
  // Artist Search
  // ------------------------------------
  export async function requestMagicLink(userEmail: string) {
    const endpoint = `/auth/requestMagicLink`;
    const body = JSON.stringify({ userEmail });
    return fetchFromAPIWithoutAuth(endpoint, (data) => data, 'POST', body);
  }
  
  export async function searchArtists(
    partialName: string,
    limit: number = 10,
    lastEvaluatedKey?: string
  ): Promise<ArtistSearchResponse> {
    const params = new URLSearchParams({
      partialName: encodeURIComponent(partialName),
      limit: limit.toString(),
    });
  
    if (lastEvaluatedKey) {
      params.append('lastEvaluatedKey', lastEvaluatedKey);
    }
  
    const endpoint = `/artist/search?${params.toString()}`;
    return fetchFromAPIWithoutAuth<ArtistSearchResponse>(
      endpoint,
      (data) => data as ArtistSearchResponse,
      'GET'
    );
  }
  
  // ------------------------------------
  // Coral-Related Functions
  // ------------------------------------
  export async function getCoralFromAPI(id: string): Promise<ClientCoral> {
    return fetchFromAPI<ClientCoral>(
      `/coral/get?id=${id}`, 
      (data) => data as ClientCoral
    );
  }
  
  export async function getCuratedCoralFromAPI(id: string): Promise<ClientCoral> {
    return fetchFromAPIWithoutAuth<ClientCoral>(
      `/coral/curated/get?id=${id}`, 
      (data) => data as ClientCoral
    );
  }
  
  export async function getSharedArtistPoolCoralDetails(
    artistPoolGuid: string
  ): Promise<{ coralName: string; coralGuid: string }> {
    const endpoint = `/artistPool/getSharedArtistPoolCoralDetails?artistPoolGuid=${encodeURIComponent(
      artistPoolGuid
    )}`;
    return fetchFromAPI<{ coralName: string; coralGuid: string }>(
      endpoint,
      (data) => data as { coralName: string; coralGuid: string },
      'GET',
      undefined,
      false
    );
  }
  
  export async function getwearecoralImpact(
    featured: boolean = false
  ): Promise<ArtistAnalytics[]> {
    const endpoint = `/analytics/impact${featured ? '?featured=true' : ''}`;
    return fetchFromAPI(endpoint, (data) => data as ArtistAnalytics[], 'GET', undefined, false);
  }
  
  export async function addTagToCoral(coralId: string, tagText: string) {
    const endpoint = `/coral/addTag`;
    const body = JSON.stringify({ coralId, tagText });
    return fetchFromAPI(endpoint, (data) => data, 'POST', body);
  }
  
  export async function removeTagFromCoral(coralId: string, tag: Tag) {
    const endpoint = `/coral/removeTag`;
    const body = JSON.stringify({ coralId, tag });
    return fetchFromAPI(endpoint, (data) => data, 'DELETE', body);
  }
  
  export async function retrieveTagsForCoral(coralId: string): Promise<Tag[]> {
    const endpoint = `/coral/retrieveTags?coralId=${encodeURIComponent(coralId)}`;
    const response = (await fetchFromAPI(endpoint, (data) => data)) as { tags: Tag[] };
    return Array.isArray(response.tags) ? response.tags : [];
  }
  
  export async function getCategoriesAndGenresForCoralBrowser(): Promise<
    { categorization: Tag; genres: Tag[] }[]
  > {
    const endpoint = `/browser/getCategoriesAndGenres`;
    const response = (await fetchFromAPI(endpoint, (data) => data)) as {
      tagsForCoralBrowser: {
        categorization: { tagType: string; tagText: string };
        genres: { tagType: string; tagText: string }[];
      }[];
    };
    return response.tagsForCoralBrowser.map(({ categorization, genres }) => ({
      categorization: { ...categorization, name: categorization.tagText, type: categorization.tagType },
      genres: genres.map((genre) => ({ ...genre, name: genre.tagText, type: genre.tagType })),
    }));
  }
  
  export async function getAllCoralTags(limit: number = Infinity): Promise<string[]> {
    const endpoint = `/browser/getAllTags?limit=${limit}`;
    const response = (await fetchFromAPI(endpoint, (data) => data)) as { tags: string[] };
    return Array.isArray(response.tags) ? response.tags : [];
  }
  
  export async function getCoralsForTag(
    tag: Tag
  ): Promise<{ coralName: string; coralGuid: string; artists: string[] }[]> {
    const endpoint = `/coral/getCoralsForTag?tagText=${encodeURIComponent(
      tag.tagText
    )}&tagType=${encodeURIComponent(tag.tagType)}`;
    const response = (await fetchFromAPI(endpoint, (data) => data)) as {
      corals: { coralName: string; coralGuid: string; artists: string[] }[];
    };
    return Array.isArray(response.corals) ? response.corals : [];
  }
  
  export async function getRecentSharedCorals(
    limit: number = 8
  ): Promise<{ coralName: string; coralGuid: string; artists: string[] }[]> {
    const endpoint = `/coral/getRecentSharedCorals?limit=${limit}`;
    const response = (await fetchFromAPI(endpoint, (data) => data, 'GET', undefined, false)) as {
      corals: { coralName: string; coralGuid: string; artists: string[] }[];
    };
    return Array.isArray(response.corals) ? response.corals : [];
  }
  
  export async function createCoralFromAPI(
    coralType: CoralType, 
    artistId?: string
  ): Promise<BaseCoral> {
    const endpoint = `/coral/create`;
    const body = JSON.stringify({
      coralType,
      ...(artistId && { artistId }),
    });
    return fetchFromAPI<BaseCoral>(endpoint, (data) => data as BaseCoral, 'POST', body, true);
  }
  
  export async function createCoralPublic(
    artistGuid?: string, 
    cache?: boolean
  ): Promise<BaseCoral> {
    let path: string;
    if (!artistGuid) {
      path = `coral-with-artists-cache/blank:${CoralType.USER}.json`;
    } else {
      path = `coral-with-artists-cache/artist:${artistGuid}:${CoralType.USER}.json`;
    }
  
    // Try to fetch from S3 first
    const s3Data = await fetchFromS3<BaseCoral>(path);
    if (s3Data) {
      return s3Data;
    }
  
    // If not in S3, fall back to API call
    const endpoint = `/coral/create/public`;
    const body = JSON.stringify({
      ...(artistGuid && { artistId: artistGuid }),
      cache: cache || false,
      coralType: CoralType.USER,
    });
    return fetchFromAPIWithoutAuth<BaseCoral>(endpoint, (data) => data as BaseCoral, 'POST', body, true);
  }
  
  export async function updateCoralSharingSettings(
    coralGuid: string, 
    isShared: boolean
  ): Promise<UpdateCoralVisibilityResponse> {
    const endpoint = `/coral/updateSharingSettings`;
    const body = JSON.stringify({ coralGuid, isShared });
    return fetchFromAPI<UpdateCoralVisibilityResponse>(
      endpoint, 
      (data) => data as UpdateCoralVisibilityResponse, 
      'POST', 
      body
    );
  }
  
  export async function updateCoralCuratedSettings(
    coralGuid: string, 
    isCurated: boolean
  ): Promise<UpdateCoralCuratedResponse> {
    const endpoint = `/coral/updateCuratedSettings`;
    const body = JSON.stringify({ coralGuid, isCurated });
    return fetchFromAPI<UpdateCoralCuratedResponse>(
      endpoint, 
      (data) => data as UpdateCoralCuratedResponse, 
      'POST', 
      body
    );
  }
  
  export async function saveCoralToAPI(coral: ClientCoral): Promise<{ guid: string }> {
    const endpoint = `/user/saveCoral`;
    const body = JSON.stringify(coral);
    return fetchFromAPI<{ guid: string }>(
      endpoint, 
      (data) => data as { guid: string }, 
      'POST', 
      body, 
      true
    );
  }
  
  export async function saveTemporaryCoralToAPI(
    coral: ClientCoral
  ): Promise<{ tempCoralId: string }> {
    const endpoint = `/coral/savetemporary`;
    const body = JSON.stringify(coral);
    return fetchFromAPIWithoutAuth<{ tempCoralId: string }>(
      endpoint, 
      (data) => data as { tempCoralId: string }, 
      'POST', 
      body, 
      true
    );
  }
  
  export async function simulateCoral(coral: ClientCoral): Promise<SimulateCoralResponse> {
    const endpoint = `/coral/simulate`;
    const body = JSON.stringify(coral);
    return fetchFromAPI<SimulateCoralResponse>(
      endpoint, 
      (data) => data as SimulateCoralResponse, 
      'POST', 
      body
    );
  }
  
  export async function simulateCoralWithoutAuth(
    coral: ClientCoral
  ): Promise<SimulateCoralResponse> {
    const endpoint = `/coral/simulateSharedCoral`;
    const body = JSON.stringify(coral);
    return fetchFromAPIWithoutAuth<SimulateCoralResponse>(
      endpoint, 
      (data) => data as SimulateCoralResponse, 
      'POST', 
      body
    );
  }
  
  export async function editSignal(
    coralId: string, 
    timestamp: string, 
    artistId: string, 
    message: string
  ): Promise<void> {
    const endpoint = `/signal/edit`;
    const body = JSON.stringify({ coralId, timestamp, artistId, message });
    await fetchFromAPI<void>(endpoint, (data) => data, 'POST', body);
  }
  
  export async function deleteSignal(
    coralId: string, 
    timestamp: string, 
    artistId: string
  ): Promise<void> {
    const endpoint = `/signal/delete`;
    const body = JSON.stringify({ coralId, timestamp, artistId });
    await fetchFromAPI<void>(endpoint, (data) => data, 'DELETE', body);
  }

  export async function addSignal(
    coralId: string,
    signal: {
        signalType: 'artist' | 'coral';
        artistId?: string;
        artistName?: string;
        message: string;
    }
): Promise<void> {
    const endpoint = `/signal/add`;
    const body = JSON.stringify({ coralId, signal });
    await fetchFromAPI<void>(endpoint, (data) => data, 'POST', body);
}

export async function getArtistsFromCoral(coralId: string, userId: string): Promise<{ 
  artists: { artistId: string; artistName: string }[];
  signals: SignalRecord[];
}> {
  const endpoint = `/coral/artists?coralId=${coralId}&userId=${userId}`;
  return fetchFromAPIWithoutAuth<{ 
    artists: { artistId: string; artistName: string }[]; 
    signals: SignalRecord[];
  }>(
    endpoint,
    (data) => data as { 
      artists: { artistId: string; artistName: string }[]; 
      signals: SignalRecord[];
    },
    'GET'
  );
}

export async function addPublicSignal(coralId: string, userId: string, signal: { 
  signalType: 'artist' | 'coral';
  artistId?: string;
  artistName?: string;
  message: string;
}): Promise<void> {
  const endpoint = `/signal/public/add`;
  const body = JSON.stringify({
    coralId,
    userId,
    signal
  });
  await fetchFromAPIWithoutAuth<void>(endpoint, (data) => data, 'POST', body);
}

export async function editPublicSignal(
  coralId: string,
  timestamp: string,
  artistId: string,
  message: string,
  userId: string
): Promise<void> {
  const endpoint = `/signal/public/edit`;
  const body = JSON.stringify({
    coralId,
    timestamp,
    artistId,
    message,
    userId
  });
  await fetchFromAPIWithoutAuth<void>(endpoint, (data) => data, 'POST', body);
}
  
  export async function getCoralPayments(
    coralId: string, 
    startDate?: string, 
    endDate?: string
  ): Promise<ProcessedCoralPayments[]> {
    const queryParams = new URLSearchParams({
      coralId: coralId,
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
    });
  
    const endpoint = `/coral/payments?${queryParams.toString()}`;
    return fetchFromAPI<ProcessedCoralPayments[]>(
      endpoint,
      (data) => data as ProcessedCoralPayments[],
      'GET',
      undefined,
      true
    );
  }
  
  // ------------------------------------
  // User-Related Functions
  // ------------------------------------
  export async function updateUserArtistConnections(
    artistConnections: Array<{ artistName: string; artistGuid: string }>
  ): Promise<void> {
    const endpoint = `/user/artists`;
    const body = JSON.stringify(artistConnections);
    await fetchFromAPI<{ message: string }>(
      endpoint, 
      (data) => data as { message: string }, 
      'POST', 
      body
    );
  }
  
  export async function getUserArtistConnections(): Promise<
    Array<{ artistName: string; artistGuid: string }>
  > {
    const endpoint = `/user/artists`;
    return fetchFromAPI<Array<{ artistName: string; artistGuid: string }>>(
      endpoint, 
      (data) => data as Array<{ artistName: string; artistGuid: string }>, 
      'GET'
    );
  }
  
  export async function createArtistApplication(
    artistApplication: ArtistApplication
  ): Promise<{ applicationId: string; status: string; artistName: string }> {
    const endpoint = `/user/application`;
    const body = JSON.stringify({ application: artistApplication });
    return fetchFromAPI<{ applicationId: string; status: string; artistName: string }>(
      endpoint,
      (data) => data as { applicationId: string; status: string; artistName: string },
      'POST',
      body
    );
  }
  
  export async function getUserArtistApplications(): Promise<
    { applicationId: string; status: string; artistName: string }[]
  > {
    const endpoint = `/user/applications`;
    return fetchFromAPI<{ applicationId: string; status: string; artistName: string }[]>(
      endpoint, 
      (data) => data as { applicationId: string; status: string; artistName: string }[], 
      'GET'
    );
  }
  
  export async function createUserIdentityVerification(
    userIdentityVerification: UserIdentityVerification
  ): Promise<{ verificationId: string; status: string }> {
    const endpoint = `/user/verification`;
    const body = JSON.stringify({ verification: userIdentityVerification });
    return fetchFromAPI<{ verificationId: string; status: string }>(
      endpoint,
      (data) => data as { verificationId: string; status: string },
      'POST',
      body
    );
  }
  
  // Connect a curated coral
  export async function connectCuratedCoral(curatedCoralId: string): Promise<void> {
    const endpoint = `/user/connectCoral`;
    const body = JSON.stringify({ curatedCoralId });
    await fetchFromAPI<void>(endpoint, (data) => data, 'POST', body);
  }
  
  // Get connected curated corals
  export async function getConnectedCuratedCorals(): Promise<UserCoralConnection[]> {
    const endpoint = `/user/getConnectedCorals`;
    return fetchFromAPI<UserCoralConnection[]>(
      endpoint, 
      (data) => data as UserCoralConnection[], 
      'GET', 
      undefined, 
      false
    );
  }
  
  // Disconnect a curated coral
  export async function disconnectCuratedCoral(curatedCoralId: string): Promise<void> {
    const endpoint = `/user/disconnectCoral`;
    const body = JSON.stringify({ curatedCoralId });
    await fetchFromAPI<void>(endpoint, (data) => data, 'POST', body);
  }
  
  export async function updateUser(user: Partial<User>): Promise<User> {
    const endpoint = `/user/update`;
    const body = JSON.stringify(user);
    return fetchFromAPI<User>(
      endpoint, 
      (data) => data as User, 
      'POST', 
      body, 
      false
    );
  }
  
  export async function getUserFromAPI(userId: string): Promise<User> {
    return fetchFromAPI<User>(
      `/user/get?id=${userId}`, 
      (data) => data as User, 
      'GET', 
      undefined, 
      false
    );
  }
  
  export async function getUserDashboard(): Promise<UserDashboard> {
    return fetchFromAPI<UserDashboard>(
      '/user/dashboard', 
      (data) => data as UserDashboard, 
      'GET', 
      undefined, 
      true
    );
  }
  
  // ------------------------------------
  // Subscription Functions
  // ------------------------------------
  export async function createCoralSubscription(
    guid: string, 
    subscriptionId: string
  ): Promise<{ guid: string }> {
    const endpoint = `/subscription/create`;
    const body = JSON.stringify({ guid, subscriptionId });
    return fetchFromAPI<{ guid: string }>(
      endpoint, 
      (data) => data as { guid: string }, 
      'POST', 
      body, 
      true
    );
  }
  
  export async function pauseCoralSubscription(guid: string): Promise<{ guid: string }> {
    const endpoint = `/subscription/pause`;
    const body = JSON.stringify({ guid });
    return fetchFromAPI<{ guid: string }>(
      endpoint, 
      (data) => data as { guid: string }, 
      'POST', 
      body, 
      true
    );
  }
  
  export async function reactivateCoralSubscription(guid: string): Promise<{ guid: string }> {
    const endpoint = `/subscription/reactivate`;
    const body = JSON.stringify({ guid });
    return fetchFromAPI<{ guid: string }>(
      endpoint, 
      (data) => data as { guid: string }, 
      'POST', 
      body, 
      true
    );
  }
  
  export async function cancelCoralSubscription(guid: string): Promise<void> {
    const endpoint = `/subscription/cancel`;
    const body = JSON.stringify({ guid });
    await fetchFromAPI<void>(
      endpoint, 
      (data) => data as void, 
      'POST', 
      body, 
      true
    );
  }
  
  export async function getPayPalPlanIdForPledgeAmount(
    amount: number
  ): Promise<{ planId: string }> {
    const endpoint = `/subscription/getPayPalPlanId?amount=${amount}`;
    return fetchFromAPIWithoutAuth<{ planId: string }>(
      endpoint, 
      (data) => data as { planId: string }, 
      'GET'
    );
  }
  
  // ------------------------------------
  // Named Export (for reuse if needed)
  // ------------------------------------
  export { fetchFromAPI };

  export async function getEcosystemContent(): Promise<{
    newCorals: CoralContentSummary[];
    recentlyProcessedCorals: CoralContentSummary[];
    recentSignals: SignalRecord[];  // Change this to use SignalRecord
}> {
    return fetchFromAPI(
        '/content/ecosystem',
        (data) => data as { 
            newCorals: CoralContentSummary[]; 
            recentlyProcessedCorals: CoralContentSummary[]; 
            recentSignals: SignalRecord[] 
        },
        'GET',
        undefined,
        false
    );
}