import { useState, useEffect, useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { getCoralFromAPI, getCuratedCoralFromAPI, createCoralFromAPI, createCoralPublic, getArtistDetails, saveCoralToAPI, saveTemporaryCoralToAPI, simulateCoral, simulateCoralWithoutAuth, updateCoralSharingSettings, createCoralSubscription as createCoralSubscription, pauseCoralSubscription, cancelCoralSubscription, updateCoralCuratedSettings, reactivateCoralSubscription } from '../api/api'; //, updateCoralShare, updateCoralFindable } from '../api/api';
import useCoralEditorArtistManagement from './useCoralEditorArtistManagement';
import { ClientCoral, CoralType, CoralSimulation, CoralSimulationResult, Source, ArtistPoolType, SourceType, SharedCoral, SignalRecord } from 'shared/types/platformTypes';
import { CoralSimulator } from 'shared/utils/coralSimulator';
import { trackEvent, EventName } from 'shared/lib/eventTracking';
import { useAuthContext } from '../components/Auth/AuthContext/AuthContext';

// Fix the type definition for getDefaultCoral
const getDefaultCoral = (): ClientCoral => ({
    subscriptionStatus: 1,
    guid: "placeholder",
    version: 1,
    baseGuid: "",
    type: CoralType.USER, // Fix: Use proper enum value
    pinnedArtists: [],
    pledgeAmount: 0,
    coralRuleSet: {
        strategyId: "default-strategy",
        version: 1,
        strategyName: "Default Strategy",
        description: "Default strategy for new corals",
        layers: [
            {
                layerType: "systemFilter",
                version: "1.0",
                guid: "system-filter-guid-1.0"
            },
            {
                layerType: "platformDefaults",
                version: "1.0",
                guid: "platform-defaults-guid-1.0"
            },
            {
                layerType: "userFiltering",
                filters: []
            },
            {
                layerType: "distribution",
                method: "EvenArtistSplit",
                parameters: {}
            }
        ],
        shareableURL: ""
    },
    artistPoolId: "",
    createdAt: new Date().toISOString(),
    lastUpdatedAt: new Date().toISOString(),
    coralName: "",
    coralShared: false,
    coralCurated: false,
    artistPool: {
        type: ArtistPoolType.BLENDED,
        artistList: []
    }
});

const useCoralEditor = (
    initialCoralId: string, 
    isShared = false, 
    artistId?: string,
    createSharedAsUserCoral = false,
    apiClient = { getCoralFromAPI, getCuratedCoralFromAPI, createCoralFromAPI, createCoralPublic, getArtistDetails, saveCoralToAPI, saveTemporaryCoralToAPI, simulateCoral, simulateCoralWithoutAuth, updateCoralSharingSettings, createCoralSubscription: createCoralSubscription, pauseCoralSubscription, cancelCoralSubscription, updateCoralCuratedSettings, reactivateCoralSubscription }
) => {
    const { userProfile, currentUser } = useAuthContext();
    const [coralData, setCoralData] = useState<ClientCoral | null>(null);
    const [updatedPledgeAmount, setUpdatedPledgeAmount] = useState<number | null>(null);
    const [simulationResults, setSimulationResults] = useState<CoralSimulation>({
        transactionFee: 0,
        platformFee: 0,
        artistAllocations: [],
    });
    // New state to store active filters
    const [activeFilters, setActiveFilters] = useState<Array<{ filterType: 'genre' | 'location'; value: string }>>([]);
    const safeSetCoralData: (updateFunction: (prevData: ClientCoral) => ClientCoral) => void = updateFunction => {
        setCoralData(prevData => {
            if (prevData === null) {
                // Handle the null case
                return null;
            }

            // Otherwise, update the ClientCoral object
            return updateFunction(prevData);
        });
    };

    const { addArtistDirectly, removeArtist, removeArtistsBySourceArtistPoolGuid, addArtistFromDynamicArtistPool, retrieveArtistsFromCoral, isDynamicArtistPools, uniqueGenres, uniqueLocations, dynamicArtistPools, sourceArtistPoolGuids } = useCoralEditorArtistManagement(coralData, activeFilters, safeSetCoralData);

    // Log updated coral to the console
    useEffect(() => {
        console.log('Updated coral:', coralData);
    }, [coralData]);

    // Fetch initial coral data
    useEffect(() => {
        const fetchCoralData = async () => {
            console.log('useEffect initialCoralId:', initialCoralId);
            let data: ClientCoral;
            
            try {
                // Case 1: Fetching existing coral
                if (initialCoralId !== '') {
                    data = isShared 
                        ? await apiClient.getCuratedCoralFromAPI(initialCoralId)
                        : await apiClient.getCoralFromAPI(initialCoralId);
                }
                // Case 2: Creating new coral with specific artist
                else if (artistId) {
                    data = !currentUser 
                        ? await apiClient.createCoralPublic(artistId, true) as ClientCoral
                        : await apiClient.createCoralFromAPI(CoralType.USER, artistId) as ClientCoral;
                }
                // Case 3: Creating brand new empty coral
                else {
                    data = getDefaultCoral();
                }

                // Handle shared coral transformation if needed
                if (isShared && createSharedAsUserCoral && data) {
                    const { userId, uniqueUrl, ...restData } = data as unknown as SharedCoral;
                    console.log('Transforming shared coral into user coral:', userId, uniqueUrl);
                    data = {
                        ...restData,
                        type: CoralType.USER,
                        guid: 'placeholder',
                        baseGuid: "",
                        coralShared: false,
                        coralCurated: false,
                        pledgeAmount: 0, // Reset pledge amount when converting to user coral
                        artistPool: {
                            ...data.artistPool,
                            type: ArtistPoolType.BLENDED,
                            artistList: data.artistPool.artistList.map(artist => ({
                                ...artist,
                                selected: true,
                                source: Source.USER,
                                sourceType: SourceType.USER_ADDED,
                                sourceArtistPoolGuid: ""
                            }))
                        }
                    };
                }
                setCoralData(data);
            } catch (error) {
                console.error('Error fetching coral data:', error);
                // Only fall back to default coral if we were trying to create an empty one
                if (!initialCoralId && !artistId) {
                    setCoralData(getDefaultCoral());
                }
            }
        };

        fetchCoralData();
    }, [initialCoralId, artistId, isShared, createSharedAsUserCoral, currentUser]);

    // Effect to update activeFilters when coralData changes
    useEffect(() => {
        // Function to extract active filters from coralData
        const extractActiveFilters = (coral: ClientCoral | null): Array<{ filterType: 'genre' | 'location'; value: string }> => {
            const filters: Array<{ filterType: 'genre' | 'location'; value: string }> = [];
            coral?.coralRuleSet.layers.forEach(layer => {
                if (layer.layerType === 'userFiltering' && layer.filters) {
                    layer.filters.forEach(filter => {
                        Object.entries(filter.parameters).forEach(([key, value]) => {
                            if (key === 'genre' || key === 'location') {
                                filters.push({ filterType: key, value });
                            }
                        });
                    });
                }
            });
            return filters;
        };

        // Set active filters based on coralData
        if (coralData) {
            setActiveFilters(extractActiveFilters(coralData));
        }
    }, [coralData]);

    const updateFilter = (filterType: 'genre' | 'location', value: string, action: 'add' | 'remove') => {
        if (coralData) {
            // Find the userFiltering layer
            const userFilteringLayer = coralData.coralRuleSet.layers.find(layer => layer.layerType === 'userFiltering');

            if (userFilteringLayer && userFilteringLayer.filters) {
                // If action is 'add', add the filter
                if (action === 'add') {
                    userFilteringLayer.filters.push({ filterType, parameters: { [filterType]: value } });
                }
                // If action is 'remove', remove the filter
                else if (action === 'remove') {
                    userFilteringLayer.filters = userFilteringLayer.filters.filter(filter => filter.parameters[filterType] !== value);
                }

                // Update the coralData state
                setCoralData({
                    ...coralData,
                    coralRuleSet: {
                        ...coralData.coralRuleSet,
                        layers: coralData.coralRuleSet.layers.map(layer =>
                            layer.layerType === 'userFiltering' ? userFilteringLayer : layer
                        ),
                    },
                });
            }
        }
    };

    const updatePledgeAmount = useCallback((newPledgeAmount: number) => {
        setCoralData(prevData => {
            if (!prevData) return null;
            if(prevData.pledgeAmount != newPledgeAmount){
                setUpdatedPledgeAmount(newPledgeAmount);  
            }
            return {
                ...prevData,
                pledgeAmount: newPledgeAmount,
            };
        });
    }, []);

    const debouncedUpdatePledgeAmount = useMemo(
        () => debounce(updatePledgeAmount, 300),
        [updatePledgeAmount]
    );

    const updateCoralName = (newCoralName: string) => {
        if (coralData) {
            setCoralData({
                ...coralData,
                coralName: newCoralName,
            });
        }
    };

    const addPinnedArtist = (artistId: string) => {
        if (coralData) {
            setCoralData({
                ...coralData,
                pinnedArtists: [...coralData.pinnedArtists, artistId],
            });
        }
    };

    const removePinnedArtist = (artistId: string) => {
        if (coralData) {
            setCoralData({
                ...coralData,
                pinnedArtists: coralData.pinnedArtists.filter(id => id !== artistId),
            });
        }
    };

    const toggleShare = async () => {
        if (coralData) {
            const newVisibilityStatus = !coralData.coralShared;
            try {
                const response = await apiClient.updateCoralSharingSettings(coralData.guid, newVisibilityStatus);
                console.log(response);
                setCoralData({ ...coralData, sharedCoralGuid: response.sharedCoralGuid, coralShared: response.isShared, uniqueUrl: response.url });
            } catch (error) {
                console.error('Failed to update coral visibility:', error);
            }
        }
    };

    const toggleCurated = async () => {
        if (coralData) {
            const newCuratedStatus = !coralData.coralCurated;
            try {
                const response = await apiClient.updateCoralCuratedSettings(coralData.guid, newCuratedStatus);
                setCoralData({ ...coralData, coralCurated: response.isCurated });
            } catch (error) {
                console.error('Failed to update coral curated status:', error);
            }
        }
    };

    const toggleFindable = async () => {
        console.log("toggleFindable method called");
        // if (coralData) {
        //   const newFindableStatus = !coralData.findable;
        //   await apiClient.updateCoralFindable(coralData.guid, newFindableStatus);
        //   setCoralData({ ...coralData, findable: newFindableStatus });
        // }
    };

    // Function to save the coral
    const saveCoral = async () => {
        let guid = null;
        if (coralData) {
            if (userProfile) {
                const response = await apiClient.saveCoralToAPI(coralData);
                guid = response.guid;
            }
            else {
                const response = await apiClient.saveTemporaryCoralToAPI(coralData);
                guid = response.tempCoralId;
            }
            // Update the coralData state with the new guid
            setCoralData({ ...coralData, guid: guid });
        }
        return guid;
    };

    // Function to simulate the coral
    const simulateCoral = useCallback(() => {
        if (coralData) {
            const coralSimulator = new CoralSimulator(coralData);
            const response = coralSimulator.process();

            const updatedArtistAllocations: CoralSimulationResult[] = response.artistAllocations.map(artist => ({
                artist_id: artist.artist_id,
                artist_name: artist.artist_name,
                spotify_popularity: artist.spotify_popularity,
                included: artist.included,
                reasons: artist.reasons,
                allocation_percentage: artist.allocation_percentage || 0,
                allocated_amount: artist.allocated_amount || 0,
                impact_100_users: Math.round((artist.allocated_amount || 0) * 100),
                impact_1000_users: Math.round((artist.allocated_amount || 0) * 1000),
                impact_5000_users: Math.round((artist.allocated_amount || 0) * 5000)
            }));

            const newSimulationResults = {
                transactionFee: response.transactionFee,
                platformFee: response.platformFee,
                artistAllocations: updatedArtistAllocations
            };

            setSimulationResults(prevResults =>
                isEqual(prevResults, newSimulationResults) ? prevResults : newSimulationResults
            );
        }
    }, [coralData]);

    const debouncedSimulateCoral = useMemo(
        () => debounce(simulateCoral, 300),
        [simulateCoral]
    );

    const createCoralSubscription = async (subscriptionId: string) => {
        if (coralData) {
            try {
                const subscriptionResponse = await apiClient.createCoralSubscription(coralData.guid, subscriptionId);
                trackEvent(EventName.CREATE_CORAL_COMPLETED, {
                    userId: userProfile!.userId,
                    coralId: coralData.guid,
                    amount: coralData!.pledgeAmount,
                    artistCount: simulationResults.artistAllocations.length
                });
                return subscriptionResponse;

            } catch (error) {
                console.error('Failed to set coral subscription to pending:', error);
            }
        }
    };

    // Function to pause the coral
    const pauseCoralSubscription = async () => {
        if (coralData) {
            try {
                return await apiClient.pauseCoralSubscription(coralData.guid);
            } catch (error) {
                console.error('Failed to pause coral subscription:', error);
            }
        }
    }

    const cancelCoralSubscription = async () => {
        if (coralData) {
            try {
                await apiClient.cancelCoralSubscription(coralData.baseGuid);
            } catch (error) {
                console.error('Failed to cancel coral subscription:', error);
            }
        }
    };

    const reactivateSubscription = async () => {
        if (coralData) {
            try {
                await apiClient.reactivateCoralSubscription(coralData.guid);
            } catch (error) {
                console.error('Failed to reactivate coral subscription:', error);
            }
        }
    };

    const updateAppreciationSignals = useCallback((signals: SignalRecord[]) => {
        setCoralData(prev => {
            if (!prev) return null;
            return {
                ...prev,
                appreciationSignals: signals
            };
        });
    }, []);

    const addAppreciationSignal = useCallback((newSignal: SignalRecord) => {
        setCoralData(prev => {
            if (!prev) return null;
            return {
                ...prev,
                appreciationSignals: [...(prev.appreciationSignals || []), newSignal]
            };
        });
    }, []);

    const updateAppreciationSignal = useCallback((timestamp: string, signalId: string, updatedMessage: string) => {
        setCoralData(prev => {
            if (!prev) return null;
            return {
                ...prev,
                appreciationSignals: prev.appreciationSignals?.map(signal => 
                    signal.createdAt === timestamp && 
                    (signal.signalType === 'artist' ? signal.artistId === signalId : signalId === 'coral')
                        ? { ...signal, message: updatedMessage }
                        : signal
                ) || []
            };
        });
    }, []);

    const removeAppreciationSignal = useCallback((timestamp: string, signalId: string) => {
        setCoralData(prev => {
            if (!prev) return null;
            return {
                ...prev,
                appreciationSignals: prev.appreciationSignals?.filter(
                    signal => !(signal.createdAt === timestamp && 
                        (signal.signalType === 'artist' ? signal.artistId === signalId : signalId === 'coral'))
                ) || []
            };
        });
    }, []);

    return {
        coralData, updatedPledgeAmount, addArtistDirectly, removeArtist, removeArtistsBySourceArtistPoolGuid,
        addArtistFromDynamicArtistPool, addPinnedArtist, removePinnedArtist, retrieveArtistsFromCoral, sourceArtistPoolGuids,
        isDynamicArtistPools, uniqueGenres, uniqueLocations, dynamicArtistPools, activeFilters,
        updateCoralName, saveCoral, createCoralSubscription: createCoralSubscription, pauseCoralSubscription, cancelCoralSubscription, simulationResults, updateFilter, toggleShare,
        toggleCurated, toggleFindable, reactivateSubscription,
        updatePledgeAmount: debouncedUpdatePledgeAmount,
        simulateCoral: debouncedSimulateCoral,
        updateAppreciationSignals,
        addAppreciationSignal,
        updateAppreciationSignal,
        removeAppreciationSignal,
    };
};

export default useCoralEditor;

